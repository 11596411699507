<template>
<div ref="mainContainer">
    <Toast />
    <div class="grid justify-content-center">
        <div class="col-12 md:col-8 lg:col-6">
            <div class="card">
                <h5>Şifre Değişimi</h5>
                <p>Eski şifrenizin doğru ve yeni tanımladığınız şifrenizin kurallara uygun olması durumunda kaydet butonu görünecektir.</p>
                <strong>Kurallar:</strong>
                <ul>
                    <li>En az 1 küçük harf</li>
                    <li>En az 1 büyük harf</li>
                    <li>En az 1 sayı</li>
                    <li>En az 6 karakter uzunluk</li>
                </ul>
                <br>
        
                <div class="grid p-fluid">
                    <div class="col-12 md:col-12">
                        <div class="field">	
                            <label for="eskisifre">Eski Şifre</label>
                            <Password id="eskisifre" v-model="userBilgileri.eskiSifre"></Password>
                        </div>
                        <div class="field">
                            <label for="yeniSifre">Yeni Şifre</label>
                            <Password id="yeniSifre" v-model="userBilgileri.yeniSifre" toggleMask mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})" strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})" autocomplete="off" >
                                <template #header>
                                    <h6>Yeni Şifre Giriniz</h6>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <p class="p-mt-2">Kriterler</p>
                                    <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                        <li>En az 1 küçük harf</li>
                                        <li>En az 1 büyük harf</li>
                                        <li>En az 1 sayı</li>
                                        <li>En az 6 karakter uzunluk</li>
                                    </ul>
                                </template>
                            </Password>
                        </div>
                        <div class="field">
                            <label for="yeniSifreTekrar">Yeni Şifre (Tekrar)</label>
                            <Password id="yeniSifreTekrar" v-model="userBilgileri.yeniSifreTekrar" toggleMask mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})" strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})" autocomplete="off">
                                <template #header>
                                    <h6>Yeni Şifreyi Tekrar Giriniz</h6>
                                </template>
                                <template #footer>
                                    <Divider />
                                    <p class="p-mt-2">Kriterler</p>
                                    <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                        <li>En az 1 küçük harf</li>
                                        <li>En az 1 büyük harf</li>
                                        <li>En az 1 sayı</li>
                                        <li>En az 6 karakter uzunluk</li>
                                    </ul>
                                </template>
                            </Password>     
                        </div>
                        <div class="field">
                            <Button v-if="butonGorunsunmu" label="Şifremi Değiştir" icon="pi pi-send" class="white-color p-button-primary p-button-raised" @click="sifreDegistir"/>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    

</template>

<script>
import CrmService from "../service/CrmService";

export default {
	data(){
		return {
			crmService: null,
            userBilgileri: {}
		}
	},
    computed: {
        butonGorunsunmu(){
            const yeniSifre = this.userBilgileri.yeniSifre;
            const yeniSifreTekrar = this.userBilgileri.yeniSifreTekrar;

            if (yeniSifre == yeniSifreTekrar) {
                debugger;
                // eslint-disable-next-line
                let mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{6,}$");
                let hardRegex = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$");
                if (mediumRegex.test(yeniSifre) || hardRegex.test(yeniSifre)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
    },
	methods: {
        async sifreDegistir(){
            if(this.userBilgileri.yeniSifre == undefined || this.userBilgileri.yeniSifre == "" || this.userBilgileri.yeniSifreTekrar == undefined || this.userBilgileri.yeniSifreTekrar == ""){
				this.$toast.add({severity:'error', summary: 'Hata', detail:'Yeni şifre alanları dolu olmalı', life: 5000});
                return;
            }
            if(this.userBilgileri.yeniSifre != this.userBilgileri.yeniSifreTekrar){
                this.$toast.add({severity:'error', summary: 'Hata', detail:'Yeni şifreler aynı olmalı', life: 5000});
                return;
            } else {
                debugger;
                let loader = this.$loading.show({
					container: this.$refs.mainContainer
				});
                try {
                    let response = response = await this.crmService.sifreDegistir(this.userBilgileri);

                    if (response) {
                        debugger;
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 3500});
						} else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Şifreniz başarıyla değiştirildi', life: 3000});
							setTimeout(() => {
								this.$router.push('/login');
							}, 2000);
						}
					}
                } catch (error) {
					this.$toast.add({severity:'error', summary: 'Hata oluştu', detail: error.message, life: 3500});
				} finally {
					loader.hide();
                }
            }
        },
	},
	async created() {
		debugger;
		this.crmService = new CrmService();
		console.log('created');
	},
    mounted(){

    }
}
</script>

<style lang="scss" scoped>

</style>
